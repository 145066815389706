import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './FloatingWhatsAppIcon.css';

const FloatingWhatsAppIcon = () => {
  useEffect(() => {
    const handleScroll = () => {
      const whatsappIcon = document.querySelector(".floating-whatsapp");
      if (window.scrollY > 100) {
        whatsappIcon.classList.add("active");
      } else {
        whatsappIcon.classList.remove("active");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <a href="https://wa.me/6281339907435" className="floating-whatsapp" target="_blank" rel="noopener noreferrer">
      <div className="whatsapp-content">
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        <span className="whatsapp-text">Hubungi Kami</span>
      </div>
    </a>
  );
};

export default FloatingWhatsAppIcon;
