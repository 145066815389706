import React from 'react';
import ServiceVVIP from '../TransportServices'; 

const defaultSubtitle = "Layanan Perjalanan Premium Kami";
const defaultTitle = "Rasakan layanan perjalanan terbaik dengan berbagai pilihan kami";

const SectionTitle = ({ subtitle = defaultSubtitle, title = defaultTitle }) => {
  return (
    <section className="section service" aria-labelledby="service-label">
      <div className="container">
        <p className="section-subtitle" id="service-label">
          {subtitle}
        </p>
        <h2 className="h2 section-title">
          {title}
        </h2>
        <ServiceVVIP/>
      </div>
    </section>
  );
};

export default SectionTitle;
