import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const BookingModal = ({ show, handleClose, carName, carPrice, carDuration, carType }) => {
    const [fullInclude, setFullInclude] = useState(carType === 'All Include');
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropoffLocation, setDropoffLocation] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [withDriver, setWithDriver] = useState(true);
    const [additionalServices, setAdditionalServices] = useState({
        wifi: false,
        snack: false,
        water: false,
        petrol: false,
    });

    const handleAdditionalServicesChange = (e) => {
        const { name, checked } = e.target;
        setAdditionalServices(prevState => ({ ...prevState, [name]: checked }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const message = `Halo, saya tertarik untuk memesan mobil ${carName} untuk durasi ${carDuration}. 
        Saya memilih ${fullInclude ? 'paket Full Include' : 'paket tidak Full Include'} dengan ${withDriver ? 'sopir' : 'tanpa sopir'}.
        Berikut rincian pemesanan saya:
        - Tanggal Penjemputan: ${pickupDate}
        - Lokasi Penjemputan: ${pickupLocation}
        - Lokasi Pengantaran: ${dropoffLocation}
        ${!fullInclude ? `- Tambahan Layanan: 
            ${additionalServices.wifi ? 'Wi-Fi, ' : ''}
            ${additionalServices.snack ? 'Snack, ' : ''}
            ${additionalServices.water ? 'Air Minum, ' : ''}
            ${additionalServices.petrol ? 'Bensin, ' : ''}` : ''}
        Apakah mobil ini tersedia? Terima kasih.`;
    
        const encodedMessage = encodeURIComponent(message.trim());
        const waUrl = `https://api.whatsapp.com/send?phone=6281339907435&text=${encodedMessage}`;
        window.open(waUrl, '_blank');
        handleClose();
    }
    

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Pesan {carName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Full Include</Form.Label>
                        <Form.Check
                            type="checkbox"
                            label="Ya"
                            checked={fullInclude}
                            onChange={(e) => setFullInclude(e.target.checked)}
                            disabled={carType === 'All Include'}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Dengan Sopir</Form.Label>
                        <Form.Check
                            type="radio"
                            label="Dengan Sopir"
                            name="driver"
                            checked={withDriver}
                            onChange={() => setWithDriver(true)}
                        />
                        <Form.Check
                            type="radio"
                            label="Tanpa Sopir"
                            name="driver"
                            checked={!withDriver}
                            onChange={() => setWithDriver(false)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Tanggal Penjemputan</Form.Label>
                        <Form.Control
                            type="date"
                            value={pickupDate}
                            onChange={(e) => setPickupDate(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Lokasi Penjemputan</Form.Label>
                        <Form.Control
                            type="text"
                            value={pickupLocation}
                            onChange={(e) => setPickupLocation(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Lokasi Pengantaran</Form.Label>
                        <Form.Control
                            type="text"
                            value={dropoffLocation}
                            onChange={(e) => setDropoffLocation(e.target.value)}
                            required
                        />
                    </Form.Group>
                    {!fullInclude && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Tambahan Layanan</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    label="Wi-Fi"
                                    name="wifi"
                                    checked={additionalServices.wifi}
                                    onChange={handleAdditionalServicesChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Snack"
                                    name="snack"
                                    checked={additionalServices.snack}
                                    onChange={handleAdditionalServicesChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Air Minum"
                                    name="water"
                                    checked={additionalServices.water}
                                    onChange={handleAdditionalServicesChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Bensin"
                                    name="petrol"
                                    checked={additionalServices.petrol}
                                    onChange={handleAdditionalServicesChange}
                                />
                            </Form.Group>
                            <div className="mb-3 text-danger">
                                <small>Catatan: Tambahan layanan akan dikenakan biaya tambahan.</small>
                            </div>
                        </>
                    )}
                    <Button variant="primary" type="submit" className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faWhatsapp} className="me-2" />
                        Pesan Sekarang
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default BookingModal;
