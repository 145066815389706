import React from 'react';
import Breadcrumb from '../atoms/Breadcrumb'; 
import CarList from '../data/CarList';
import './CarListPage.css';

const CarListPage = () => {
  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Car List' } 
  ];

  return (
    <div className="container mt-4">
      <Breadcrumb items={breadcrumbItems} />
      <CarList />
    </div>
  );
};
 

export default CarListPage;
