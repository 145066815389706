import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IonIcon } from '@ionic/react';
import { carOutline, starOutline, businessOutline, airplaneOutline } from 'ionicons/icons';

const services = [
  {
    id: 1,
    icon: carOutline,
    title: 'Rental Mobil Terpercaya, Harga Terbaik',
    text: 'Kami menyediakan layanan rental mobil dengan harga terbaik dan jaminan kepercayaan. Setiap mobil kami periksa secara berkala untuk memastikan kualitas dan keamanan.',
  },
  {
    id: 2,
    icon: starOutline,
    title: 'Layanan Antar Jemput Bandara',
    text: 'Nikmati kenyamanan layanan antar jemput bandara kami. Kami menawarkan penjemputan dan pengantaran tepat waktu dengan mobil berkualitas tinggi untuk perjalanan Anda yang lebih mudah.',
  },
  {
    id: 3,
    icon: businessOutline,
    title: 'Service VVIP',
    text: 'Layanan VVIP kami menyediakan fasilitas dan kenyamanan premium untuk pelanggan kami. Dapatkan perlakuan khusus dan perhatian ekstra dengan setiap layanan.',
  },
  {
    id: 4,
    icon: airplaneOutline,
    title: 'Paket Tour Menarik',
    text: 'Kami menawarkan berbagai paket tour menarik yang dirancang khusus untuk kebutuhan liburan Anda. Jelajahi destinasi eksotis dengan panduan profesional dan layanan terbaik kami.',
  },
];

const ServiceSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Durasi animasi dalam milidetik
    });
  }, []);

  return (
    <section className="section service" aria-labelledby="service-label">
      <div className="container">
        <p className="section-subtitle" id="service-label">Apa yang Kami Tawarkan?</p>
        <h2 className="h2 section-title">
          Kami Menawarkan Solusi Rental Mobil dan Paket Tour Sesuai Kebutuhan Anda
        </h2>
        <ul className="grid-list">
          {services.map((service, index) => (
            <li key={service.id} data-aos="fade-up" data-aos-delay={index * 200}>
              <div className="service-card">
                <div className="card-icon">
                  <IonIcon icon={service.icon} aria-hidden="true" />
                </div>
                <h3 className="h4 card-title">{service.title}</h3>
                <p className="card-text">{service.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ServiceSection;
