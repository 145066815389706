import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HeroSection = () => {
  return (
    <section className="hero-section d-flex align-items-center" style={{ 
      minHeight: '100vh', 
      backgroundColor: '#1a1a1a',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <Helmet>
        <title>Kawan Bali Trans Tour & Trevel</title>
        <meta name="description" content="Ingin merasakan Bali dengan cara yang berbeda? Sewa Alphard mewah kami dan ciptakan petualangan yang sesuai dengan minat Anda. Dengan sopir berpengalaman sebagai pemandu, Anda dapat menjelajahi tempat-tempat tersembunyi, menikmati kuliner lokal autentik, atau sekadar bersantai di pantai pribadi. Biarkan kami mewujudkan liburan impian Anda." />
        <meta name="keywords" content="Sewa Alphard Bali, Rental Mobil Mewah Bali, Liburan Bali, Sewa Mobil dengan Sopir Bali, Kawan Bali Trans, Travel Bali, Kunjungi Bali" />
        <meta name="author" content="Kawan Bali Trans" />
        <link rel="canonical" href="https://kawanbalitrans.com/daftar-mobil" />
        <meta property="og:title" content="Jelajahi Bali dengan Gaya Hidup Mewah | Kawan Bali Trans" />
        <meta property="og:description" content="Ingin merasakan Bali dengan cara yang berbeda? Sewa Alphard mewah kami dan ciptakan petualangan yang sesuai dengan minat Anda." />
        <meta property="og:url" content="https://kawanbalitrans.com/daftar-mobil" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://raw.githubusercontent.com/AnggaPuspa/foto-rental/refs/heads/main/kawnabalitrans.png" />
      </Helmet>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero-content" style={{ position: 'relative', zIndex: 2 }}>
              <h1 className="display-1 mb-3 font-weight-bold" style={{ 
                color: '#fff',
                fontFamily: "'Montserrat', sans-serif",
                letterSpacing: '2px',
                textTransform: 'uppercase'
              }}>
                Jelajahi Bali dengan Gaya Hidup Mewah
              </h1>
              <h2 className="display-3 mb-4" style={{
                color: '#D4AF37',
                fontFamily: "'Playfair Display', serif",
                fontStyle: 'italic'
              }}>
               #SewaAlphardTermurah
              </h2>
              <p className="lead mb-5" style={{ 
                fontSize: '1.5rem', 
                color: '#e0e0e0',
                fontFamily: "'Roboto', sans-serif",
                fontWeight: '300',
                lineHeight: '1.8'
              }}>
             Ingin merasakan Bali dengan cara yang berbeda? Sewa Alphard mewah kami dan ciptakan petualangan yang sesuai dengan minat Anda. Dengan sopir berpengalaman sebagai pemandu, Anda dapat menjelajahi tempat-tempat tersembunyi, menikmati kuliner lokal autentik, atau sekadar bersantai di pantai pribadi. Biarkan kami mewujudkan liburan impian Anda.
              </p>
              <Link to="/daftar-mobil" className="btn btn-primary btn-lg mr-3" style={{ 
                backgroundColor: '#D4AF37', 
                borderColor: '#D4AF37',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: '700',
                padding: '15px 35px',
                borderRadius: '30px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                textTransform: 'uppercase'
              }}>
                Pesan Sekarang
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero-image" style={{
              backgroundImage: "url('https://raw.githubusercontent.com/AnggaPuspa/foto-rental/refs/heads/main/kawnabalitrans.png')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '300px',
              position: 'relative',
              zIndex: 1
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(45deg, rgba(26,26,26,0.7) 0%, rgba(26,26,26,0) 100%)',
                zIndex: 1
              }}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;