import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCar, faWifi, faCookieBite, faGlassWater, faGasPump } from '@fortawesome/free-solid-svg-icons';
import './CarCard.css';
import BookingModal from './atoms/BookingModal';

const CarCard = ({ name, price, duration, type, imageUrl, passengers, wifi, snack, water, petrolType, drive }) => {
    const [showModal, setShowModal] = useState(false);

    const handleOrderClick = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    return (
        <div className="col-md-4 mb-4">
            <div className="card car-card h-100 shadow-lg border-0 rounded-3 position-relative">
                <img src={imageUrl} className="card-img-top car-card-img" alt={name} />
                <div className="card-body car-card-body">
                    <div className="d-flex justify-content-between align-items-start mb-3">
                        <h5 className="card-title mb-0">{name}</h5>
                        <span className={`badge ${type === 'All Include' ? 'bg-success text-white' : 'bg-secondary'}`}>
                            {type}
                        </span>
                    </div>
                    <p className="card-text mb-4">
                        <strong>{price} / {duration}</strong>
                    </p>
                    <div className="card-icons mb-3">
                        <div className="icon-group">
                            <i><FontAwesomeIcon icon={faCar} /></i>
                            <span>{passengers} Penumpang</span>
                        </div>
                        <div className="icon-group">
                            <i><FontAwesomeIcon icon={faWifi} /></i>
                            <span>{wifi ? 'Wi-Fi Tersedia' : 'Tanpa Wi-Fi'}</span>
                        </div>
                        <div className="icon-group">
                            <i><FontAwesomeIcon icon={faCookieBite} /></i>
                            <span>{snack ? 'Snack Termasuk' : 'Tanpa Snack'}</span>
                        </div>
                        <div className="icon-group">
                            <i><FontAwesomeIcon icon={faGlassWater} /></i>
                            <span>{water ? 'Air Minum Termasuk' : 'Tanpa Air Minum'}</span>
                        </div>
                        <div className="icon-group">
                            <i><FontAwesomeIcon icon={faGasPump} /></i>
                            <span>{petrolType}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleOrderClick} className="btn btn-primary d-flex align-items-center car-card-button">
                            <FontAwesomeIcon icon={faWhatsapp} className="me-2" />
                            Pesan Sekarang
                        </button>
                    </div>
                </div>
            </div>
            <BookingModal
                show={showModal}
                handleClose={handleCloseModal}
                carName={name}
                carPrice={price}
                carDuration={duration}
                carType={type}
            />
        </div>
    );
}

export default CarCard;
