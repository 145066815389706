import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./PaketTourSection.css";
import { FaWhatsapp, FaClock } from "react-icons/fa";
import { tourPackages } from "./data/tourPackages";
import AOS from 'aos';
import 'aos/dist/aos.css';

const PaketTourSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [userData, setUserData] = useState({
    name: "",
    participants: "",
  });
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleOrderClick = (tour) => {
    setSelectedTour(tour);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setUserData({ name: "", participants: "" });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleModalSubmit = () => {
    if (userData.name && userData.participants) {
      const message = `Halo, saya ${userData.name} ingin memesan ${selectedTour.title} dengan harga ${selectedTour.price} untuk durasi ${selectedTour.duration}. Jumlah peserta: ${userData.participants}. Apakah tersedia?`;
      const encodedMessage = encodeURIComponent(message);
      const waUrl = `https://api.whatsapp.com/send?phone=6281339907435&text=${encodedMessage}`;
      window.open(waUrl, "_blank");
      handleModalClose();
    } else {
      alert("Please fill out all fields");
    }
  };

  const handleShowAllToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <section className="section project" aria-labelledby="project-label">
      <div className="container py-5 packet-tour-section" id="packet-tour-section">
        <div className="text-center jarak">
          <div className="container">
            <p className="section-subtitle" id="service-label" data-aos="fade-up">
              PENAWARAN TOUR TERPOPULER
            </p>
            <h2 className="h2 section-title" data-aos="fade-up" data-aos-delay="100">
              Penawaran Tour Paling Populer
            </h2>
          </div>
        </div>
        <div className="row">
          {tourPackages.slice(0, showAll ? tourPackages.length : 3).map((tour, index) => (
            <div key={tour.id} className="col-lg-4 col-md-6 mb-4" data-aos="fade-up" data-aos-delay={index * 100}>
              <div className="card shadow-sm border-0 rounded-3 overflow-hidden h-100">
                <img src={tour.imgSrc} className="card-img-top" alt={tour.title} />
                <div className="card-body d-flex flex-column">
                  <div className="d-flex flex-column align-items-center">
                    <h3 className="packet-tour-title">{tour.title}</h3>
                  </div>
                  <p className="packet-tour-description text-center">
                    {tour.description}
                  </p>
                  <div className="d-flex justify-content-between align-items-center mt-auto">
                    <div className="d-flex align-items-center">
                      <FaClock className="me-2" />
                      <span>{tour.duration}</span>
                    </div>
                    <div className="fw-bold">{tour.price}</div>
                  </div>
                  <button
                    className="btn btn-success mt-3"
                    onClick={() => handleOrderClick(tour)}
                  >
                    <FaWhatsapp className="me-2" />
                    Booking This Tour
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Booking {selectedTour?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Nama</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukkan nama Anda"
                  name="name"
                  value={userData.name}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group controlId="formParticipants" className="mb-3">
                <Form.Label>Jumlah Peserta</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Masukkan jumlah peserta"
                  name="participants"
                  value={userData.participants}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleModalSubmit}>
              Oke
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="text-center mt-5">
          <Button variant="secondary" onClick={handleShowAllToggle} data-aos="fade-up">
            {showAll ? 'Lihat Lebih Sedikit' : 'Lihat Semua Paket Tour'}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default PaketTourSection;
