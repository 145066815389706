export const tourPackages = [
    {
      id: 1,
      title: 'Paket 1: ATV, River Tubing, & Tlaga Singha',
      description: 'Adventure tanpa batas! Nikmati ATV, river tubing, dan keindahan Tlaga Singha. ',
      imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/paket1.jpeg',
      duration: '3 hari 2 malam',
      price: 'Rp 2.500.000',
      rating: 4.5, // Rating out of 5
    },
    {
      id: 2,
      title: 'Paket 2: ATV, Rafting, & Tlaga Singha',
      description: 'Paket paling seru! Rasakan adrenalin dengan ATV, arungi jeram, dan bersantai di Tlaga Singha.',
      imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/paket2.jpeg',
      duration: '2 hari 1 malam',
      price: 'Rp 1.800.000',
      rating: 4.7, // Rating out of 5
    },
    {
      id: 3,
      title: 'Paket 3: ATV, Snorkeling, & Tlaga Singha ',
      description: 'Paket liburan yang memadukan petualangan dan relaksasi. Jelajahi alam dengan ATV, snorkeling di Blue Lagoon, dan nikmati pemandangan indah di Tlaga Singha.',
      imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/paket3.jpeg',
      duration: '4 hari 3 malam',
      price: 'Rp 3.200.000',
      rating: 4.8, 
    },
    {
      id: 4,
      title: 'Paket 4: Breakfast with Orangutan, ATV, & Tlaga Singha ',
      description: 'Liburan yang tak terlupakan! Dapatkan pengalaman berharga berinteraksi dengan orangutan, rasakan adrenalin dengan ATV, dan bersantai di infinity pool Tlaga Singha.',
      imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/paket4.jpeg',
      duration: '4 hari 3 malam',
      price: 'Rp 3.200.000',
      rating: 4.8, 
    },
    {
      id: 5,
      title: 'Paket 5: ATV, Jungle Swing, & Tlaga Singha ',
      description: 'Liburanmu makin seru dengan paket ATV, jungle swing, dan infinity pool. Nikmati keindahan alam dan ciptakan kenangan tak terlupakan.',
      imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/paket5.jpeg',
      duration: '4 hari 3 malam',
      price: 'Rp 3.200.000',
      rating: 4.8, 
    }
  ];
  