// data/galleryImages.js
export const galleryImages = [
    {
      src: 'https://images.pexels.com/photos/2166559/pexels-photo-2166559.jpeg',
      alt: 'Pura Ulun Danu Bedugul Tabanan '
    },
    {
      src: 'https://images.pexels.com/photos/2587004/pexels-photo-2587004.jpeg',
      alt: 'Diamond Beach Nusa Penida'
    },
    {
      src: 'https://images.pexels.com/photos/9359159/pexels-photo-9359159.jpeg',
      alt: 'Uluwatu Kecak Dance '
    },
    {
      src: 'https://images.pexels.com/photos/20993674/pexels-photo-20993674/free-photo-of-seni-kesenian-tengara-penunjuk.jpeg',
      alt: 'Garuda Wisnu Kencana '
    },
    {
      src: 'https://images.pexels.com/photos/9260617/pexels-photo-9260617.jpeg',
      alt: 'Tanah Lot Tabanan'
    },
    {
      src: 'https://images.pexels.com/photos/5939986/pexels-photo-5939986.jpeg',
      alt: 'Pantai Sanur'
    },
  ];
  