import React, { useState } from 'react';
import './CustomTourForm.css'; // Import custom CSS for styling

const CustomTour = () => {
  const [formData, setFormData] = useState({
    destination: '',
    duration: '',
    numberOfPeople: '',
    travelDate: '',
    accommodation: '',
    budget: '',
    activities: '',
    specialRequests: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      destination,
      duration,
      numberOfPeople,
      travelDate,
      accommodation,
      budget,
      activities,
      specialRequests
    } = formData;

    const message = `Halo, saya tertarik untuk membuat tour custom ke *${destination}*. 
      Berikut adalah rincian permintaan saya:
      - *Durasi*: ${duration}
      - *Jumlah Orang*: ${numberOfPeople}
      - *Tanggal Perjalanan*: ${travelDate}
      - *Jenis Akomodasi*: ${accommodation}
      - *Anggaran*: IDR ${budget}
      - *Aktivitas yang Diinginkan*: ${activities}
      - *Permintaan Khusus*: ${specialRequests}
    Apakah tour ini bisa diatur? Terima kasih.`;

    const encodedMessage = encodeURIComponent(message.trim());
    const waUrl = `https://api.whatsapp.com/send?phone=6281339907435&text=${encodedMessage}`;
    window.open(waUrl, '_blank');
  };

  return (
    <section className="custom-tour-section">
      <div className="custom-tour-container">
        <h2 className="custom-tour-title">Buat Tour Anda Sendiri di Bali</h2>
        <p className="custom-tour-subtitle">Isi formulir di bawah ini untuk merancang perjalanan wisata ke Bali yang sesuai dengan preferensi Anda.</p>
        <form onSubmit={handleSubmit} className="custom-tour-form">
          <div className="custom-tour-form-group">
            <label htmlFor="destination">Destinasi</label>
            <select
              id="destination"
              name="destination"
              value={formData.destination}
              onChange={handleChange}
              required
              className="custom-tour-input"
            >
              <option value="">Pilih destinasi</option>
              <option value="Pantai">Pantai</option>
              <option value="Hutan">Hutan</option>
              <option value="Pegunungan">Pegunungan</option>
              <option value="Desa">Desa</option>
              <option value="Kota">Kota</option>
            </select>
          </div>
          <div className="custom-tour-form-group">
            <label htmlFor="duration">Durasi</label>
            <input
              type="text"
              id="duration"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              required
              placeholder="Misal: 3 hari 2 malam"
              className="custom-tour-input"
            />
          </div>
          <div className="custom-tour-form-group">
            <label htmlFor="numberOfPeople">Jumlah Orang</label>
            <input
              type="number"
              id="numberOfPeople"
              name="numberOfPeople"
              value={formData.numberOfPeople}
              onChange={handleChange}
              required
              placeholder="Masukkan jumlah orang"
              className="custom-tour-input"
            />
          </div>
          <div className="custom-tour-form-group">
            <label htmlFor="travelDate">Tanggal Perjalanan</label>
            <input
              type="date"
              id="travelDate"
              name="travelDate"
              value={formData.travelDate}
              onChange={handleChange}
              required
              className="custom-tour-input"
            />
          </div>
          <div className="custom-tour-form-group">
            <label htmlFor="accommodation">Jenis Akomodasi</label>
            <select
              id="accommodation"
              name="accommodation"
              value={formData.accommodation}
              onChange={handleChange}
              required
              className="custom-tour-input"
            >
              <option value="">Pilih jenis akomodasi</option>
              <option value="Hotel">Hotel</option>
              <option value="Villa">Villa</option>
              <option value="Resort">Resort</option>
              <option value="Guesthouse">Guesthouse</option>
            </select>
          </div>
          <div className="custom-tour-form-group">
            <label htmlFor="budget">Anggaran (IDR)</label>
            <input
              type="number"
              id="budget"
              name="budget"
              value={formData.budget}
              onChange={handleChange}
              required
              placeholder="Masukkan anggaran Anda"
              className="custom-tour-input"
            />
          </div>
          <div className="custom-tour-form-group">
            <label htmlFor="activities">Aktivitas yang Diinginkan</label>
            <input
              type="text"
              id="activities"
              name="activities"
              value={formData.activities}
              onChange={handleChange}
              required
              placeholder="Misal: Snorkeling, Hiking, etc."
              className="custom-tour-input"
            />
          </div>
          <div className="custom-tour-form-group">
            <label htmlFor="specialRequests">Permintaan Khusus</label>
            <textarea
              id="specialRequests"
              name="specialRequests"
              value={formData.specialRequests}
              onChange={handleChange}
              placeholder="Masukkan permintaan khusus"
              className="custom-tour-textarea"
            ></textarea>
          </div>
          <button type="submit" className="custom-tour-btn">Kirim</button>
        </form>
      </div>
    </section>
  );
};

export default CustomTour;
