import React, { useState } from 'react';
import CarCard from '../CarCard';
import './CarList.css'; // Import custom CSS for CarList

const carData = [
    {
        name: 'Alphard Transformers Facelift',
        price: 'IDR 2,400,000',
        duration: '12 jam',
        type: 'All Include',
        category: 'Luxury',
        imageUrl: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/alfabig.jpeg',
        passengers: 4,
        wifi: true,
        snack: true,
        water: true,
        petrolType: 'Bensin Termasuk',
        drive: false,
    },
    {
        name: 'Vellfire Facelift',
        price: 'IDR 2,400,000',
        duration: '12 jam',
        type: 'All Include',
        category: 'Luxury',
        imageUrl: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/alfabig.jpeg',
        passengers: 4,
        wifi: true,
        snack: true,
        water: true,
        petrolType: 'Bensin Termasuk',
        drive: false,
    },
    {
        name: 'Porsche',
        price: 'IDR 10,000,000',
        duration: '12 jam',
        type: 'Self Drive',
        category: 'Luxury',
        imageUrl: 'https://imgcdn.oto.com/large/gallery/exterior/31/248/porsche-panamera-front-angle-low-view-659479.jpg',
        passengers: 2,
        wifi: true,
        snack: false,
        water: false,
        petrolType: 'Bensin Termasuk',
        drive: true,
    },
    {
        name: 'Mini Cooper Cabrio',
        price: 'IDR 4,000,000',
        duration: '12 jam',
        type: 'Self Drive',
        category: 'Sport',
        imageUrl: 'https://www.mini.co.id/content/dam/MINI/common/home/mini-editions/mini-convertible-seaside/mini-seaside-hometeaser-wide.jpg',
        passengers: 2,
        wifi: false,
        snack: false,
        water: false,
        petrolType: 'Bensin Termasuk',
        drive: true,
    },
    {
        name: 'Fortuner TRD / GR',
        price: 'IDR 1,600,000',
        duration: '12 jam',
        type: 'All Include',
        category: 'SUV',
        imageUrl: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/pajeroo.jpeg',
        passengers: 5,
        wifi: false,
        snack: false,
        water: false,
        petrolType: 'Bensin Termasuk',
        drive: true,
    },
    {
        name: 'New Avanza / New Xenia',
        price: 'IDR 675,000',
        duration: '12 jam',
        type: 'All Include',
        category: 'Economy',
        imageUrl: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/alpa.jpeg',
        passengers: 5,
        wifi: false,
        snack: false,
        water: false,
        petrolType: 'Bensin Termasuk',
        drive: true,
    },
    // ... (Tambahkan data mobil lainnya dengan kategori)
];

const CarList = () => {
    const [filter, setFilter] = useState('All');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleFilterChange = (category) => {
        setFilter(category);
        setDropdownOpen(false);
    };

    const filteredCars = carData.filter(car =>
        filter === 'All' || car.category === filter
    );

    return (
        <div className="container mt-5">
            <div className="filter-container">
                <div className="filter-button" onClick={() => setDropdownOpen(!dropdownOpen)}>
                    <span>Filter by {filter === 'All' ? 'Category' : filter}</span>
                    <div className={`arrow ${dropdownOpen ? 'up' : 'down'}`} />
                </div>
                {dropdownOpen && (
                    <div className="filter-menu">
                        <div
                            className={`filter-item ${filter === 'All' ? 'active' : ''}`}
                            onClick={() => handleFilterChange('All')}
                        >
                            All
                        </div>
                        <div
                            className={`filter-item ${filter === 'Luxury' ? 'active' : ''}`}
                            onClick={() => handleFilterChange('Luxury')}
                        >
                            Luxury
                        </div>
                        <div
                            className={`filter-item ${filter === 'Sport' ? 'active' : ''}`}
                            onClick={() => handleFilterChange('Sport')}
                        >
                            Sport
                        </div>
                        <div
                            className={`filter-item ${filter === 'SUV' ? 'active' : ''}`}
                            onClick={() => handleFilterChange('SUV')}
                        >
                            SUV
                        </div>
                        <div
                            className={`filter-item ${filter === 'Economy' ? 'active' : ''}`}
                            onClick={() => handleFilterChange('Economy')}
                        >
                            Economy
                        </div>
                    </div>
                )}
            </div>
            <div className="row">
                {filteredCars.map((car, index) => (
                    <CarCard
                        key={index}
                        name={car.name}
                        price={car.price}
                        duration={car.duration}
                        type={car.type}
                        imageUrl={car.imageUrl}
                        passengers={car.passengers}
                        wifi={car.wifi}
                        snack={car.snack}
                        water={car.water}
                        petrolType={car.petrolType}
                        drive={car.drive}
                    />
                ))}
            </div>
        </div>
    );
}

export default CarList;
