import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { galleryImages } from './data/galleryData';
import './GallerySection.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const GallerySection = () => {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleToggleClick = () => {
    setShowAll(!showAll);
  };

  return (
    <section className="gallery-section">
      <div className="container d-flex flex-column align-items-center">
        <div className="text-center mb-4" data-aos="fade-up">
          <p className="section-subtitle" id="service-label">
            Destination Gallery
          </p>
          <h2 className="h2 section-title">
            Find out your favourite destination
          </h2>
        </div>
        <div className="gallery-images d-flex flex-wrap justify-content-center">
          {galleryImages.slice(0, showAll ? galleryImages.length : 3).map((image, index) => (
            <div key={index} className="gallery-image" data-aos="fade-up" data-aos-delay={index * 100}>
              <img src={image.src} alt={image.alt} />
              <div className="image-text">{image.alt}</div>
              {image.additionalInfo && (
                <div className="image-overlay">
                  <span className="additional-info">{image.additionalInfo}</span>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="text-center mt-5" data-aos="fade-up">
          <Button variant="secondary" onClick={handleToggleClick}>
            {showAll ? 'Lihat Lebih Sedikit' : 'Lihat Semua Foto'}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
