import React from 'react';
import { useLocation } from 'react-router-dom';
import { servicesData } from '../data/servicesData';
import './globl.css'

const ServiceDetailPage = () => {
  const location = useLocation();
  const service = servicesData.find(service => service.link === location.pathname);

  if (!service) return <p>Layanan tidak ditemukan.</p>;

  // Data dummy untuk informasi tambahan
  const additionalInfo = {
    features: [
      'Penerimaan VIP di Bandara',
      'Penanganan Bagasi Khusus',
      'Layanan Prioritas Keamanan',
      'Konsultan Pribadi',
      'Transfer ke Hotel atau Tujuan Lain'
    ],
    pricing: {
      standard: 'IDR 1.500.000',
      premium: 'IDR 2.500.000',
      exclusive: 'IDR 3.500.000'
    }
  };

  // URL WhatsApp untuk pemesanan
  const whatsappUrl = `https://wa.me/6281339907435?text=Halo,%20saya%20tertarik%20dengan%20layanan%20${encodeURIComponent(service.title)}.%20Mohon%20informasi%20lebih%20lanjut.%20Terima%20kasih!`;

  return (
    <div className="container py-5 gloval">
      <h1>{service.title}</h1>
      <img src={service.imgSrc} alt={service.title} className="img-fluid mb-4" />
      <p>{service.description}</p>

      <h2>Fitur Utama</h2>
      <ul>
        {additionalInfo.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>

      <h2>Harga</h2>
      <ul>
        {Object.entries(additionalInfo.pricing).map(([type, price]) => (
          <li key={type}><strong>{type.charAt(0).toUpperCase() + type.slice(1)}:</strong> {price}</li>
        ))}
      </ul>

      <h2>Reservasi</h2>
      <p>Untuk melakukan reservasi atau mendapatkan informasi lebih lanjut, silakan hubungi kami melalui WhatsApp:</p>
      <a href={whatsappUrl} className="btn btn-success" target="_blank" rel="noopener noreferrer">
        <i className="bi bi-whatsapp"></i> Hubungi via WhatsApp
      </a>

      <div className="mt-4">
        <a href="/" className="btn btn-secondary">Kembali ke Beranda</a>
      </div>
    </div>
  );
};

export default ServiceDetailPage;
